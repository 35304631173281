import * as React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/header";

const AboutPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <>
      <Header/>
      <Layout location={location} title={siteTitle}>
        <h1>Lab</h1>
        <p>Welcome to my lab, a list of projects I made that are worth checking out.</p>
        <p>I will slowly update this page whenever some of my works need to be mentioned.</p>
        <ul>
          <li>listhonise - This is a very simple to-do list <a href="https://github.com/rajojon23/listhonize"> https://github.com/rajojon23/listhonize </a></li>
          <li>croopy - This is a very simple comment dashboard <a href="https://github.com/rajojon23/croopy"> https://github.com/rajojon23/croopy </a></li>
        </ul>
      </Layout>
    </>
  )
}

export default AboutPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`